import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { ILessonDecorated } from '@/types/main'
import { BOOKING_FINALITY } from '@/utils/constants'
import { buildSurveyRoute } from '@/utils/route'

interface IProps {
  lesson: ILessonDecorated
}

export const LessonCtaTag: React.FC<IProps> = ({ lesson }) => {
  if (lesson.isFinished()) {
    if (['Available', 'Completed'].includes(lesson.authUserSurveyStatus)) {
      return (
        <StyledButtonLink href={buildSurveyRoute(lesson)}>
          <FormattedMessage
            defaultMessage="{surveyCompleted, select, true {Modifier} other {Donner}} mon avis"
            description="LessonCtaTag: survey"
            values={{
              surveyCompleted: lesson.authUserSurveyStatus === 'Completed',
            }}
          />
        </StyledButtonLink>
      )
    }

    const finalityBookings = lesson.getAuthUserFinalityBookings()
    if (finalityBookings === BOOKING_FINALITY.NONE) {
      return null
    }

    // Use guestEmail info only if unique lessonVisit ("My bookings" context)
    const { guestEmail = null } =
      lesson.authUserVisits.length === 1 ? lesson.authUserVisits[0] : {}

    return (
      <FormattedMessage
        tagName={TagStyled}
        defaultMessage={`
          {finalityBookings, select, lateCanceled {Annulation en retard} present {Présent} absent {Absent} other {?}}
          {guestEmail, select, null {} other {({guestEmail})}}
        `}
        description="LessonCtaTag: finalityBookings"
        values={{
          finalityBookings,
          guestEmail,
        }}
      />
    )
  }

  const activeBookings = lesson.getAuthUserActiveBookings()
  const lessonVisit = activeBookings[0] ?? null

  if (lesson.hasAuthUserWaitlistBookings()) {
    return (
      <FormattedMessage
        tagName={TagStyled}
        defaultMessage={`
          Inscrit sur liste d'attente
          {guestEmail, select, null {} other {({guestEmail})}}
        `}
        description="LessonCtaTag: waiting list"
        values={{
          guestEmail: lessonVisit?.guestEmail,
        }}
      />
    )
  }

  if (activeBookings.length) {
    return (
      <FormattedMessage
        tagName={TagStyled}
        defaultMessage={`
          Inscrit à ce cours
          {guestEmail, select, null {} other {({guestEmail})}}
        `}
        description="LessonCtaTag: registered"
        values={{
          guestEmail: lessonVisit?.guestEmail,
        }}
      />
    )
  }

  if (lesson.authUserVisits?.[0]?.lateCanceled) {
    return (
      <FormattedMessage
        tagName={TagStyled}
        defaultMessage="Annulé en retard"
        description="LessonCtaTag: lateCanceled"
      />
    )
  }

  if (lesson.fullBooked) {
    return (
      <FormattedMessage
        tagName={TagStyled}
        defaultMessage="Cours complet"
        description="LessonCtaTag: lesson is full"
      />
    )
  }

  if (new Date(lesson.startAt) <= new Date()) {
    return (
      <FormattedMessage
        tagName={TagStyled}
        defaultMessage="Cours débuté"
        description="LessonCtaTag: lesson has started"
      />
    )
  }

  if (lesson.availableCapacity <= 2) {
    return (
      <FormattedMessage
        tagName={TagStyled}
        defaultMessage="Plus que quelques places"
        description="LessonCtaTag: lesson is almost full"
      />
    )
  }
}

const TagStyled = styled.p`
  ${({ theme }) => theme.typo.mention}

  color: ${({ theme }) => theme.color.grey};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  text-align: center;
`
const StyledButtonLink = styled(CustomButtonLink)`
  padding: 12px;
`
