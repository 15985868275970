import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { NextImageAvatar } from '@/components/_shared/NextImage'
import { NextLink } from '@/components/_shared/NextLink'
import { ITeacher } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'
import { buildInstructorRoute } from '@/utils/route'

interface IProps {
  teacher: ITeacher
  avatarSize?: string
}

export const LessonListItemTeacher: React.FC<IProps> = ({
  teacher,
  avatarSize = '72px',
}) => {
  if (!teacher?.name) {
    return null
  }

  return (
    <StyledLink href={buildInstructorRoute(teacher)}>
      <TeacherAvatarWrapper $avatarSize={avatarSize}>
        <NextImageAvatar src={teacher.avatarPath} alt={teacher.name} sizes="72px" />
      </TeacherAvatarWrapper>

      <FormattedMessage
        defaultMessage="avec {teacherName}."
        description="LessonListItem: teacher"
        values={{ teacherName: teacher.name }}
      />
    </StyledLink>
  )
}

const StyledLink = styled(NextLink)`
  align-items: center;
  color: ${({ theme }) => theme.color.grey};
  display: flex;
  width: fit-content;
  z-index: 1;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: center;
    flex-direction: row;
    gap: ${({ theme }) => theme.spacing.s};

    &:hover > * {
      color: ${({ theme }) => theme.color.terracota};
      transition: color 300ms;
    }
  }
`
const TeacherAvatarWrapper = styled.div<{ $avatarSize: string }>`
  display: none;

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    display: block;
    height: ${({ $avatarSize }) => $avatarSize};
    min-width: ${({ $avatarSize }) => $avatarSize};
    position: relative;
    width: ${({ $avatarSize }) => $avatarSize};
  }
`
