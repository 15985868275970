import React from 'react'
import { FormattedDate } from 'react-intl'
import styled, { css } from 'styled-components'

import { LinkCover } from '@/components/_shared/style'
import { TimeAndDuration } from '@/components/_shared/TimeAndDuration'
import { PlaceCity } from '@/components/place/misc/PlaceCity'
import { ILessonDecorated, ILessonType } from '@/types/main'
import { DEVICE_WIDTH } from '@/utils/constants'
import { buildLessonSessionRoute } from '@/utils/route'

import { LessonCta } from './misc/LessonCta'
import { LessonListItemTeacher } from './misc/LessonListItemTeacher'
import { LessonListItemTitle } from './misc/LessonListItemTitle'

interface IProps {
  lesson: ILessonDecorated
  className?: string
}

export const LessonListItem: React.FC<IProps> = ({ lesson, className }) => (
  <LessonListItemWrapper className={className} $lessonType={lesson.type}>
    <LinkCover href={buildLessonSessionRoute(lesson)} />

    <LessonListItemTitle lesson={lesson} />

    <DateColumn>
      <TimeAndDuration startTime={lesson.startAt} duration={lesson.duration} />
      <FormattedDate value={lesson.startAt} weekday="long" day="numeric" month="long" />
    </DateColumn>

    <PlaceCity lessonSerie={lesson} />

    <LessonListItemTeacher teacher={lesson.teacher} />

    <LessonListItemMainButton lesson={lesson} scopeListing />
  </LessonListItemWrapper>
)

const LessonListItemWrapper = styled.div<{ $lessonType: string }>`
  border-top: 1px solid ${({ theme }) => theme.color.greyLighter};
  display: grid;
  padding: ${({ theme }) => theme.spacing.s} 0;
  position: relative;
  width: 100%;

  ${({ $lessonType }) =>
    ['masterClass', 'workshop'].includes($lessonType)
      ? css`
          background-color: ${({ theme }) => theme.color.aquaLight};

          &:hover {
            background-color: ${({ theme }) => theme.color.aquaLighter};
          }
        `
      : css`
          background-color: ${({ theme }) => theme.color.white};

          &:hover {
            background-color: ${({ theme }) => theme.color.beigeLight};
          }
        `}

  & > *:not(:nth-last-child(2)) {
    margin-bottom: ${({ theme }) => theme.spacing.xs};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: center;
    gap: ${({ theme }) => theme.spacing.s};
    grid-template-columns: 3fr 2fr 2fr 3fr 2fr;
    padding: ${({ theme }) => `${theme.spacing.m} ${theme.spacing.ms}`};

    & > *:not(:last-child) {
      margin-bottom: 0;
    }
  }
`
const DateColumn = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.color.grey};
  display: flex;

  & > * {
    margin-right: ${({ theme }) => theme.spacing.s};
  }

  @media screen and (min-width: ${DEVICE_WIDTH.TABLET}px) {
    align-items: flex-start;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xs};

    & > * {
      margin-right: 0;
    }
  }
`
const LessonListItemMainButton = styled(LessonCta)`
  z-index: 1;

  @media screen and (max-width: ${DEVICE_WIDTH.TABLET}px) {
    margin-top: 5px;
  }
`
