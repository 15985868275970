import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { PlaceIcon } from '@/components/_svg/icons/PlaceIcon'
import { ILessonDecorated, ISerieDecorated } from '@/types/main'

import { PlaceB2B } from './PlaceB2B'
import { PlaceOutdoor } from './PlaceOutdoor'

interface IProps {
  lessonSerie: ILessonDecorated | ISerieDecorated
}

export const PlaceCity: React.FC<IProps> = ({ lessonSerie }) => {
  if (lessonSerie.isOnline()) {
    return (
      <Wrapper>
        <FormattedMessage
          defaultMessage="Cours en ligne"
          description="PlaceCity: online"
        />
        {lessonSerie.isB2B() && <PlaceB2B />}
      </Wrapper>
    )
  }

  if (lessonSerie.place) {
    return (
      <Wrapper>
        <div>
          <StyledPlaceIcon />
          {lessonSerie.place.city}
        </div>

        {lessonSerie.isB2B() && <PlaceB2B />}
        {!lessonSerie.isB2B() && lessonSerie.place.outdoor && <PlaceOutdoor />}
      </Wrapper>
    )
  }

  if (lessonSerie.isB2B()) {
    return <PlaceB2B />
  }

  return (
    <Wrapper>
      <StyledPlaceIcon />
      <FormattedMessage defaultMessage="Indéterminé" description="PlaceCity: unknown" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  gap: ${({ theme }) => theme.spacing.xs};
  grid-template-columns: 1fr;
`
const StyledPlaceIcon = styled(PlaceIcon)`
  height: ${({ theme }) => theme.spacing.s};
  margin-right: 5px;
  vertical-align: text-top;
  width: ${({ theme }) => theme.spacing.s};
`
