import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

import { CustomButtonLink } from '@/components/_custom/CustomButton'
import { ILessonDecorated } from '@/types/main'
import { buildLessonSessionRoute } from '@/utils/route'

import { LessonCtaButton } from './LessonCtaButton'
import { LessonCtaTag } from './LessonCtaTag'

interface IProps {
  lesson: ILessonDecorated
  scopeListing?: boolean
  className?: string
}

export const LessonCta: React.FC<IProps> = ({
  lesson,
  scopeListing = false,
  className,
}) => {
  if (lesson.replayAvailable) {
    return (
      <Wrapper className={className}>
        <CustomButtonLink href={buildLessonSessionRoute(lesson)}>
          <FormattedMessage defaultMessage="Replay" description="LessonCta: see replay" />
        </CustomButtonLink>
        <LessonCtaTag lesson={lesson} />
      </Wrapper>
    )
  }

  return (
    <Wrapper className={className}>
      <LessonCtaTag lesson={lesson} />
      <LessonCtaButton lesson={lesson} scopeListing={scopeListing} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: normal;
  display: grid;
  gap: 1px;
`
