import React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { theme } from 'theme'

import { CustomButton, CustomButtonLink } from '@/components/_custom/CustomButton'
import { useLessonBooking } from '@/contexts/lessonBooking'
import { useSWRAuth } from '@/hooks/swr/useSwr-auth'
import { ILessonDecorated } from '@/types/main'
import { buildLessonBookingRoute, buildLessonSessionRoute, routes } from '@/utils/route'

interface IProps {
  lesson: ILessonDecorated
  scopeListing: boolean
}

export const LessonCtaButton: React.FC<IProps> = ({ lesson, scopeListing }) => {
  const { setBookLesson } = useLessonBooking()
  const { authenticated } = useSWRAuth()

  if (lesson.isFinished()) {
    return null
  }

  const activeBookings = lesson.getAuthUserActiveBookings()
  if (activeBookings.length) {
    return (
      <FlexWrapper>
        {lesson.userCancellationAllowed && (
          <SmallButton
            onClick={() => {
              setBookLesson({ lesson, action: 'unbook', lessonVisit: activeBookings[0] })
            }}
            outlined
          >
            <FormattedMessage
              defaultMessage="Annuler {nbActiveBookings, select, 1 {} other {({nbActiveBookings})}}"
              description="LessonCtaButton > Button cancel"
              values={{
                nbActiveBookings: activeBookings.length,
              }}
            />
          </SmallButton>
        )}

        {lesson.userInvitationAllowed && (
          <SmallButtonLink
            href={{
              pathname: routes.bookingInvite,
              query: {
                uuid: lesson.uuid,
              },
            }}
            outlined
          >
            <FormattedMessage
              defaultMessage="Inviter"
              description="LessonCtaButton > Button invite"
            />
          </SmallButtonLink>
        )}
      </FlexWrapper>
    )
  }

  const mainButtonHref =
    scopeListing && !authenticated
      ? buildLessonSessionRoute(lesson)
      : buildLessonBookingRoute(lesson)

  if (lesson.fullBooked) {
    if (!lesson.waitlistEnable) {
      return null
    }

    return (
      <CustomButtonLink href={mainButtonHref}>
        <FormattedMessage
          defaultMessage="Liste d'attente"
          description="LessonCtaButton: waiting list"
        />
      </CustomButtonLink>
    )
  }

  return (
    <CustomButtonLink
      href={mainButtonHref}
      color={['masterClass', 'workshop'].includes(lesson.type) ? theme.color.aqua : null}
    >
      <FormattedMessage
        defaultMessage="{isFree, select, true {Réserver gratuitement} other {Réserver}}"
        description="LessonCtaButton: book"
        values={{
          isFree: lesson.authUserAccess === 'yes-free',
        }}
      />
    </CustomButtonLink>
  )
}
const FlexWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.xs};
  justify-content: center;
`
const SmallButton = styled(CustomButton)`
  flex: 2;
  padding-left: ${({ theme }) => theme.spacing.s};
  padding-right: ${({ theme }) => theme.spacing.s};
`
const SmallButtonLink = styled(CustomButtonLink)`
  flex: 1;
  padding-left: ${({ theme }) => theme.spacing.ms};
  padding-right: ${({ theme }) => theme.spacing.ms};
`
