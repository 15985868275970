import styled from 'styled-components'

import { NextLink } from '@/components/_shared/NextLink'
import { DEVICE_WIDTH } from '@/utils/constants'

export const LinkCover = styled(NextLink)`
  inset: 0;
  position: absolute;
`

export const CarouselMobileOnly = styled.div`
  -ms-overflow-style: none;
  display: flex;
  gap: ${({ theme }) => theme.spacing.ms};
  overflow-x: auto;
  padding: 0 ${({ theme }) => theme.spacing.ms};
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (min-width: ${DEVICE_WIDTH.MOBILE}px) {
    gap: 7%;
    padding: 0 8.33%;
  }
`

export const NoBreak = styled.span`
  white-space: pre;
`
