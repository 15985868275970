import React from 'react'
import styled from 'styled-components'

import { LessonListItemFavoriteToggler } from '@/components/_shared/FavoriteToggler'
import { ILessonDecorated } from '@/types/main'

interface IProps {
  lesson: ILessonDecorated
}

export const LessonListItemTitle: React.FC<IProps> = ({ lesson }) => {
  return (
    <Wrapper>
      <div>
        <Title>{lesson.title}</Title>
        <Level>{lesson.level?.title}</Level>
      </div>
      {lesson.serie && <LessonListItemFavoriteToggler lessonSerie={lesson} />}
    </Wrapper>
  )
}
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`
const Title = styled.p`
  ${({ theme }) => theme.typo.h5}
  ${({ theme }) => theme.typo.bold}

flex: 1;
`
const Level = styled.p`
  color: ${({ theme }) => theme.color.grey};
  margin-top: ${({ theme }) => theme.spacing.xs};
`
